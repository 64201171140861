.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-thin::-webkit-scrollbar {
    width: 1px;
    height: 8px;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    background: transparent;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* styles.css */
@keyframes move {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}

@keyframes gradientShiftB3F0FE {
  0% {
    background: linear-gradient(145deg, #B3F0FE, #8AD1F1, #5CA9F3);
  }
  25% {
    background: linear-gradient(165deg, #8AD1F1, #5CA9F3, #B3F0FE);
  }
  50% {
    background: linear-gradient(185deg, #5CA9F3, #B3F0FE, #8AD1F1);
  }
  75% {
    background: linear-gradient(205deg, #B3F0FE, #5CA9F3, #8AD1F1);
  }
  100% {
    background: linear-gradient(225deg, #B3F0FE, #8AD1F1, #5CA9F3);
  }
}

@keyframes gradientShift000A66 {
  0% {
    background: linear-gradient(145deg, #000A66, #00008B, #0000AA);
  }
  25% {
    background: linear-gradient(165deg, #00008B, #0000AA, #000A66);
  }
  50% {
    background: linear-gradient(185deg, #0000AA, #000A66, #00008B);
  }
  75% {
    background: linear-gradient(205deg, #000A66, #0000AA, #00008B);
  }
  100% {
    background: linear-gradient(225deg, #000A66, #00008B, #0000AA);
  }
}

@keyframes gradientShiftD4B3FE {
  0% {
    background: linear-gradient(145deg, #D4B3FE, #B394E6, #9E84D4);
  }
  25% {
    background: linear-gradient(165deg, #B394E6, #9E84D4, #D4B3FE);
  }
  50% {
    background: linear-gradient(185deg, #9E84D4, #D4B3FE, #B394E6);
  }
  75% {
    background: linear-gradient(205deg, #D4B3FE, #9E84D4, #B394E6);
  }
  100% {
    background: linear-gradient(225deg, #D4B3FE, #B394E6, #9E84D4);
  }
}

@keyframes gradientShift4BAFFF {
  0% {
    background: linear-gradient(145deg, #4BAFFF, #3A91CC, #2A72AA);
  }
  25% {
    background: linear-gradient(165deg, #3A91CC, #2A72AA, #4BAFFF);
  }
  50% {
    background: linear-gradient(185deg, #2A72AA, #4BAFFF, #3A91CC);
  }
  75% {
    background: linear-gradient(205deg, #4BAFFF, #2A72AA, #3A91CC);
  }
  100% {
    background: linear-gradient(225deg, #4BAFFF, #3A91CC, #2A72AA);
  }
}

/* Define the pulsate animation */
@keyframes pulsate {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* General circle style with animation */
.circle {
  position: absolute;
  border-radius: 50%;
  animation: move 20s linear infinite, spin 2s ease-in-out infinite;
}

/* Apply the gradientShift animations to the circles */
.circle.gradientB3F0FE {
  animation: move 20s linear infinite, pulsate 2s ease-in-out infinite, gradientShiftB3F0FE 10s ease-in-out infinite;
}

.circle.gradient000A66 {
  animation: move 20s linear infinite, pulsate 2s ease-in-out infinite, gradientShift000A66 10s ease-in-out infinite;
}

.circle.gradientD4B3FE {
  animation: move 20s linear infinite, pulsate 2s ease-in-out infinite, gradientShiftD4B3FE 10s ease-in-out infinite;
}

.circle.gradient4BAFFF {
  animation: move 20s linear infinite, pulsate 2s ease-in-out infinite, gradientShift4BAFFF 10s ease-in-out infinite;
}


.circle:nth-child(1) {
  width: 60px;
  height: 60px;
  left: 20%;
  animation-duration: 15s, 2s; /* Separate animation durations for move and pulsate */
  animation-delay: -2s, 0s; /* Separate animation delays for move and pulsate */
}

.circle:nth-child(2) {
  width: 80px;
  height: 80px;
  left: 50%;
  animation-duration: 20s, 2s;
  animation-delay: -3s, 0s;
}

.circle:nth-child(3) {
  width: 100px;
  height: 100px;
  left: 70%;
  animation-duration: 25s, 2s;
  animation-delay: -4s, 0s;
}

/* Add the same animation for the dynamically generated circles */
.pulsate {
  animation: spin 5s ease-in-out infinite;
}

/* Media query for small screens */
@media (max-width: 640px) {
  .image-container {
      display: none;
  }

  .image-background {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 200px; /* Adjust height as needed */
      border-radius: 1rem;
      transition: transform 0.3s ease;
  }

  .image-background:hover {
      transform: scale(1.05);
  }
}


